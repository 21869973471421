<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="col-xxl-12">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h5 class="font-weight-bold text-dark">Detail Tempat Usaha</h5>

                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <table class="table table-borderless">
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Nama Usaha</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span>{{ detail.business_name }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Komoditas</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span>{{ detail.display_commodity_name }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Bidang Usaha</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span>{{ detail.village_business_category_name }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Status Kepemilikan Tempat Usaha</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span>{{ detail.display_business_place_status }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Produk Unggulan</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span>{{ detail.product }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Modal Usaha</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span>{{
                              parseInt(detail.capital).toLocaleString("id-ID")
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Omset Usaha</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span
                              >{{
                                parseInt(detail.turnover).toLocaleString(
                                  "id-ID"
                                )
                              }}
                              /bulan</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-1" width="200">
                            <span>Alamat Lengkap</span>
                          </th>
                          <th class="pl-0"><span>:</span></th>
                          <td class="pl-2">
                            <span>{{ detail.address }}</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-8">
                      <div class="openstreetmap mt-3" v-if="detail">
                        <l-map
                          style="height: 500px"
                          :zoom="zoom"
                          :center="center"
                        >
                          <l-tile-layer
                            :url="url"
                            :attribution="attribution"
                          ></l-tile-layer>
                          <l-marker :lat-lng="center"></l-marker>
                        </l-map>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="secondary"
                    @click="$router.push('/village-business')"
                  >
                    Kembali
                  </b-button>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="success"
                    @click="
                      $router.push('/village-business/edit/' + $route.params.id)
                    "
                    v-if="btnAccess"
                  >
                    Edit
                  </b-button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<style>
.wizard-steps {
  height: 172px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.leaflet-popup-close-button {
  display: none;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";
import module from "@/core/modules/CrudModule.js";

import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = "";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "master-topics-detail",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      detail: {},
      // access
      btnAccess: true,

      zoom: 20,
      center: [-6.30749877, 107.290332172],
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      id: "mapbox/streets-v11",
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [{ name: "Karawang", position: [-6.30749877, 107.290332172] }],
    };
  },
  methods: {
    getData() {
      ApiService.get("village-business/" + this.$route.params.id)
        .then((response) => {
          //(response);
          this.detail = response.data.data;
          this.center = [this.detail.latitude, this.detail.longitude];

          // this.getRoleById(response.data.data.role_id);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },

    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Data Tempat Usaha", route: "/village-business" },
      { title: "Detail Tempat Usaha" },
    ]);
    this.getData();

    // this.getUserById();
    // this.checkAccess();
  },
};
</script>
